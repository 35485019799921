<template>
  <!-- <div class="demand_container">
    <div class="content flex_row">
      <div v-for="item in list" :key="item.id" class="main_wrap">
        <div style="width: 590px; height: 258px">
          <img :src="item.logo" alt="" />
        </div>
        <div class="bgbox">
          <div class="flex_row_aic">
            <p class="title">{{ item.title }}</p>
            <p>
              (可购数量：<span>{{ item.limit_num }}</span> 套)
            </p>
          </div>

          <div>
            <p class="time">活动时间：{{ item.time_str }}</p>
            <div>倒计时：{{ item.djs }}</div>
          </div>
        </div>
        <div class="main">
          <div class="flex_row goods_box">
            <div v-for="sub in item.goods" :key="sub.id" class="item">
              <img class="url" style="width: 125px; height: 125px" :src="sub.image.url" alt="" />
              <p class="shenglue_1 c3 bold">{{ sub.title }}</p>
              <p class="shenglue_1 c3 f12">{{ sub.sku }}</p>
              <p class="shenglue_1 c9 f12">{{ sub.spscqy }}</p>
              <div class="flex_row_aic">
                <p class="red mt_10 bold">￥{{ sub.price }}</p>
                <p class="f12 mt_10 c9 huaxianjia" v-if="sub.line_price">￥{{ sub.line_price }}</p>
              </div>
              <div class="biao">
                <p>{{ sub.num }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="flex_row_aic">
            <p class="p1"><span class="f14">套餐价：</span>{{ item.price }}</p>
            <p class="p2" v-if="item.show_cart == '101'">{{ item.line_price }}</p>
          </div>
          <div class="flex_row_aic" v-if="item.show_cart == '101'">
            <p class="red bold" v-if="item.discount > 0">省：￥{{ item.discount }}</p>
            <div v-if="item.stock" class="btn flex_c_c" @click="handleBuy(item.id)">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="demand_container">
    <div class="headImg">
      <img src="@/assets/img/demand/head_img.jpg" alt="" />
    </div>
    <div class="contents">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="title">
          <img src="@/assets/img/demand/title.png" alt="" />
          <p class="text">{{ item.title }}</p>
        </div>
        <div class="propagate">
          <img src="@/assets/img/demand/czzh.jpg" alt="" />
        </div>
        <div style="display: flex; justify-content: space-between; flex: 1">
          <div class="assist">
            <div class="goodsBox" id="goodsBox">
              <div class="goods" v-for="(elem,i) in item.goods" :key="i">
                <div class="imgBox">
                  <img :src="elem.image.url" alt="" />
                </div>
                <p class="name">{{ elem.title }}</p>
                <p>{{ elem.spscqy }}</p>
                <p>规格：{{ elem.sku }}</p>
                <p class="numTag">
                  <span>数量:{{ elem.num }}</span>
                </p>
                <p v-if="item.shopid != '50'">单价:￥{{ elem.line_price }}</p>
                <p class="hdj">活动价：￥{{ elem.price }}</p>
                <!-- <img class="goodsBj" src="./img/border.png" alt=""> -->
              </div>
              <template
                v-if="item.goodslist ? item.goodslist.length > 3 : false"
              >
                <i
                  @click="setScroll('right')"
                  class="el-icon-video-play left"
                ></i>
                <i
                  @click="setScroll('left')"
                  class="el-icon-video-play right"
                ></i>
              </template>
            </div>
          </div>
          <div class="item_right">
            <div class="priceBox">
              <div>
                <p>指导价</p>
                <p class="price">{{ item.line_price }}</p>
              </div>
              <div class="jia">-</div>
              <div>
                <p>组合优惠</p>
                <p class="price">{{ item.discount }}</p>
              </div>
              <div class="jia">=</div>
              <div>
                <p>到手价</p>
                <p class="price">{{ item.price }}</p>
              </div>
            </div>
            <div class="descendBox">
              <img src="@/assets/img/demand/descend.png" alt="" />
              <p class="floatYh">{{ item.discount }}</p>
            </div>
            <div class="zzjg">
              套餐到手价<span>￥</span
              ><span style="font-size: 18px; font-weight: bold">{{
                item.price
              }}</span>
            </div>
            <div v-if="item.stock > 0" class="addBox">
              <el-input-number
                :max="item.limit_num<item.stock?item.limit_num:item.stock"
                size="mini"
                v-model="item.dcsl"
                :min="1"
                label="描述文字"
              ></el-input-number>
              <div class="addBtn" @click="handleBuy(item.id)">立即抢购</div>
            </div>
            <div v-else class="addBox kcbzBox">
              <el-input-number
                size="mini"
                v-model="item.dcsl"
                disabled
                :min="1"
                label="描述文字"
              ></el-input-number>
              <div class="addBtn">库存不足</div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-if="list.length <= 0">
        <img
        src="@/assets/img/demand/empty.png"
        alt=""
      />
      </div>
    </div>
  </div>
</template>

<script>
import { DeepClone } from "@/utils/tool.js";
export default {
  data() {
    return { list: [] };
  },
  created() {
    this.$api("zone.getPackage").then((res) => {
      this.list = res.data.list;
      let arr = DeepClone(this.list);
      arr = arr.map((obj) => {
        
        if (obj.time > 0) {
          this.$set(obj, "djs", this.InitTime(obj.time));
        }
        return {...obj, dcsl: 1};//需要增加一个步进值
      });
      console.log(arr);
      this.list = arr;
      // console.log("组合列表---", this.list);
    });
  },
  mounted() {
    setInterval(() => {
      for (var key in this.list) {
        var rightTime = Math.floor(
          (this.list[key]["time"] * 1000 - Date.now()) / 1000
        );
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 60) % 60);
          var ss = Math.floor(rightTime % 60);
        }
        this.list[key]["djs"] = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      }
    }, 1000);
  },
  methods: {
    InitTime(endtime) {
      var dd,
        hh,
        mm,
        ss = null;
      var time = Math.floor((endtime * 1000 - Date.now()) / 1000);
      if (time <= 0) {
        return "结束";
      } else {
        dd = Math.floor(time / 60 / 60 / 24);
        hh = Math.floor((time / 60 / 60) % 24);
        mm = Math.floor((time / 60) % 60);
        ss = Math.floor(time % 60);
        var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
        return str;
      }
    },

    handleBuy(id) {
      this.$api("goods.addCart", { id, number: 1 }).then(() => {
        this.$store.dispatch("getBadge");
        this.$message.success("加入购物车成功");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.red {
  color: @priceRed;
}
// --------------------------
span,
div,
ul,
li,
p {
  color: #333;
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.demand_container {
  // padding: 20px 0;
  .headImg {
    width: 100%;
    height: 320px;
    > img {
      width: 100%;
      height: 320px;
    }
  }
  .contents {
    width: 1250px;
    margin: 30px auto;
    box-sizing: border-box;
    border: 5px solid #ffd46c;
    box-shadow: 0 0 4px 5px #e6bf5e;
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    .empty{
      margin: 100px auto;
      width: 300px;
    }
    .item {
      width: 1200px;
      height: 292px;
      border: 2px solid red;
      border-radius: 6px;
      margin-bottom: 28px;
      display: flex;
      position: relative;
      .propagate{
        height: 292px;
        width: 292px;
        >img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        position: absolute;
        top: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 367px;
        height: 42px;
        z-index: 9;
        >img{
          width: 100%;
          height: 100%;
        }
        .text{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
      }
      .assist {
        position: relative;
        width: 424px;
      }
      .goodsBox {
        width: 680px;
        white-space: nowrap;
        margin-top: 32px;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-color: transparent transparent; /* Chrome, Safari, and Opera */
        &::-webkit-scrollbar {
          height: 0px;
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          // background-color: rgba(51, 51, 51, 0.8);
          height: 0px;
        }
        // ie的滚动条样式
        scrollbar-arrow-color: #fff;
        scrollbar-face-color: #fff;
        scrollbar-darkshadow-color: #fff;
        scrollbar-base-color: #fff;
        scrollbar-track-color: #fff;
        scrollbar-highlight-color: #fff;
        scrollbar-shadow-color: #fff;
        .goods {
          display: inline-block;
          width: 140px;
          box-sizing: border-box;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          margin-left: 14px;
          .imgBox {
            width: 135px;
            height: 135px;
            margin: 0 auto;
            border: 1px solid #eee;
            > img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
          .name {
            font-size: 14px;
            font-weight: bold;
          }
          .numTag {
            > span {
              background-color: #f84f0c;
              padding: 0 3px;
              border-radius: 4px;
              color: #fff;
              font-size: 12px;
            }
          }
          > p {
            margin-left: 10px;
            margin-top: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 13px;
            &:nth-of-type(4) {
              color: red;
            }
            &:nth-of-type(5) {
              color: #868686;
              text-decoration: line-through;
            }
          }
          .hdj{
            font-weight: bold;
            font-size: 14px;
            color: red;
          }
          .goodsBj {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .el-icon-video-play {
          font-size: 34px;
          cursor: pointer;
          opacity: 0.4;
        }
        .left {
          position: absolute;
          left: 10px;
          top: 49px;
          z-index: 9;
          transform: rotate(180deg);
        }
        .right {
          position: absolute;
          right: 13px;
          top: 49px;
          z-index: 9;
        }
      }
      .item_right {
        padding-right: 5px;
        width: 191px;
        margin-top: 35px;
        .addBox {
          display: flex;
          align-items: center;
          border: 1px solid red;
          // background-color: red;
          background: linear-gradient(to right, #ff0404, #f84f0c);
          box-sizing: border-box;
          border-radius: 4px;
          .el-input-number--mini {
            width: 90px;
          }
          .el-input-number--mini .el-input__inner {
            padding-left: 0;
            padding-right: 0;
            font-weight: bold;
          }
          .el-input-number--mini .el-input-number__decrease,
          .el-input-number--mini .el-input-number__increase {
            width: 20px;
          }
          .addBtn {
            white-space: nowrap;
            color: #fff;
            cursor: pointer;
            flex: 1;
            text-align: center;
            height: 36px;
            line-height: 36px;
          }
        }
        .kcbzBox{
          border: 1px solid #ccc;
          background: #ccc;
        }
        .priceBox {
          width: 100%;
          height: 52px;
          background: linear-gradient(to bottom, #f87c43, #f83131);
          display: flex;
          align-items: center;
          border-radius: 4px;
          margin-top: -16px;
          justify-content: space-around;
          > div {
            font-size: 12px;
            white-space: nowrap;
            color: #fff;
            // transform: scale(0.88);
            > p {
              color: #fff;
              text-align: center;
            }
            .price {
              font-size: 14px;
              margin-top: 2px;
            }
          }
          .jia {
            font-size: 14px;
          }
        }
        .zzjg {
          font-size: 12px;
          text-align: center;
          margin: 5px 0;
          > span {
            color: red;
          }
        }
        .descendBox {
          width: 100%;
          height: 109px;
          margin-top: 10px;
          position: relative;
          > img {
            width: 100%;
            height: 100%;
          }
          .floatYh {
            position: absolute;
            width: 100px;
            top: 23px;
            left: 46px;
            text-align: center;
            color: #ffd05b;
            font-size: 18px;
            font-weight: 600;
            text-shadow: 2px 2px 1px #821a1a;
          }
        }
      }
    }
  }
}


</style>
